import countryData from "../utils/country-data";

export default class CountryService {
  static async getRegions() {
    try {
      let regions = countryData;
      return regions;
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al obtener las regiones",
      };
    }
  }

  static async getCommunes(regionId) {
    try {
      let region;
      if (typeof regionId === "string" || regionId instanceof String) {
        region = countryData.find((element) => element.name === regionId);
      } else if (!isNaN(parseFloat(regionId)) && !isNaN(regionId - 0)) {
        region = countryData.find((element) => element.number === regionId);
      } else {
        return [];
      }
      let communes = region.communes ? region.communes : [];
      return communes;
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al obtener las comunas",
      };
    }
  }
}
