<template>
  <v-app>
      <!-- <NavBar /> -->
    <v-main>
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
// import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Footer,
    // NavBar
  },
  data: () => ({
  }),
};
</script>