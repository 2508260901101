<template>
  <v-container>

    <v-row justify="center">

      <v-dialog v-model="dialogFamiliar" width="430px">
        <v-card>
          <v-card-text>
            <div class="text-center">

              <br>
              <br>
              <label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 18px; line-height: 140%; letter-spacing: 0.02em;
                      color: black; flex: none; order: 1; flex-grow: 0;">Estas son las coberturas
                del </label>
              <label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 18px; line-height: 140%; letter-spacing: 0.02em;
                      color: black; flex: none; order: 1; flex-grow: 0;">Plan<label
                  style="font-family: Helvetica; font-style: normal; font-weight: 600; 
                      font-size: 20px; line-height: 140%; letter-spacing: 0.02em; color: #2D387C; flex: none; order: 1; flex-grow: 0; text-transform: uppercase;">
                  {{ info[0][indexInfo] }}</label> Seguro Oncológico de Southbridge.
              </label>

              <br>
              <br>
              <label class="text-center encabezado">{{ info[1][indexInfo] }} Reembolso Gastos médicos tratamiento
                Oncológico familiar.</label>
              <br>
              <br>
              <label class="texto">En caso de que te diagnostiquen a ti o algún integrante de tu familia que esté
                contigo
                en el seguro algún tipo de cáncer,
                podrán iniciar el tratamiento en Centro Médico donde <label class="textoAzul">reembolsaremos
                  el {{ info[1][indexInfo] }} de los gastos</label> del tratamiento por hasta <label
                  class="textoAzul">UF
                  {{ info[2][indexInfo] }} por cada asegurado </label>(más de 1.000 millones de pesos).
                <br>Si quieres revisar más en detalle los gastos que cubre haz clic <a :href="document"
                  target="_blank">aquí</a> </label>
              <br>
              <br>
              <label class="text-center encabezado">Indemnización {{ info[3][indexInfo] }} UF Libre disposición ante
                diagnóstico positivo.</label>
              <br>
              <br>
              <label class="texto">En caso de que te diagnostiquen a ti o algún integrante de tu familia que esté
                contigo
                en el seguro algún tipo de cáncer, podrás solicitar a la compañía
                <label class="textoAzul">{{ info[1][indexInfo] }}</label> de indemnización para que lo usen en lo que
                quieran, como alojamientos y traslados para tu tratamiento o comprarse lo que necesiten.
                <label class="textoAzul">
                  Sin preguntas</label>.</label>
              <br>
              <br>
              <label class="texto">Esto pueden solicitarlo con la confirmación de <label class="textoAzul">UC
                  CHRISTUS</label> de
                contratación y hasta de un diagnóstico positivo de cáncer.</label>
              <br>
              <br>
              <label class="text-center encabezado">Agrega a integrantes de tu familia sin cambiar el precio del
                seguro.</label>
              <br>
              <br>
              <label class="texto">El plan {{ info[0][indexInfo] }} permite que agregues a tu <label
                  class="textoAzul">cónyuge/pareja/conviviente </label>
                y a <label class="textoAzul">todos </label>tus y sus <label class="textoAzul">hijos e hijas
                </label>(hasta
                24 años de edad) por el <label class="textoAzul">mismo valor del plan</label>, y cada uno con la
                protección de UF 30.000 de tratamiento y con las UF {{ info[5][indexInfo] }} de indemnización ante
                diagnostico positivo. </label>
              <br>
              <br>
              <label class="text-center encabezado">Protección desde el día que contratas</label>
              <br>
              <br>
              <label class="texto">La protección de este seguro comienza desde el <label class="textoAzul">día que
                  contratas</label>.
                Olvídate de carencias o semanas para que comience a protegerte. </label>
              <br>

            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn style="box-sizing: border-box; padding: 12px 24px; gap: 10px; background: #FFB600; border: 2px solid #FFB600;
                    border-radius: 8px; margin-top: -6%; font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 14px; line-height: 17px;
                    letter-spacing: 0.02em; color: white; flex: none; order: 0; flex-grow: 0; margin:auto;"
              elevation="0" text @click="dialogFamiliar = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-row>

    <br>

    <NavBar :step="2"></NavBar>

    <Titulo></Titulo>

    <br>

    <br v-if="screen < 800">

    <v-stepper v-model="e1" elevation="0">
      <v-stepper-header>
        <div class="circle-step">
          <v-stepper-step class="stepForms" style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1"
            step="1">
            Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Plan
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Info
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" step="4"> DPS </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" step="5"> Datos </v-stepper-step>
        </div>
      </v-stepper-header>

      <br>

      <v-stepper-items>
        <v-stepper-content step="3">
          <div>

            <v-form ref="form" v-model="valid">

              <div class="formData">

                <br>
                <br>

                <h3 class="text-center labelPlanes">
                  Agrega a tu grupo familiar
                </h3>
                <br>
                <h4 v-if="screen > 800" class="text-center labelSubPlanes">
                  ¡Puedes asegurar hasta 7 familiares! <br> <b>Estos pueden ser tu cónyuge/pareja/conviviente e
                    hijos.</b>
                </h4>

                <h4 v-else class="text-center labelSubPlanes2">
                  ¡Puedes asegurar hasta 7 familiares! <br> <b>Estos pueden ser tu cónyuge/pareja/conviviente e
                    hijos.</b>
                </h4>

                <br>
                <br>

                <v-row v-for="(benef, index) in benefData" :key="index">

                  <v-col>

                    <v-select :class="styleParentesco" :items="personType" v-model="benef.tipo" label="Parentesco"
                      :rules="parentescoRules" return-object required filled solo @change="change()"></v-select>

                  </v-col>

                  <v-col>

                    <v-select v-if="benef.tipo != 'Hijo(a)'" :class="styleEdad" :items="agelist" v-model="benef.edad"
                      item-text="name" label="Edad" :rules="edadRules" return-object required filled solo
                      @change="mayorEdad()">
                    </v-select>

                    <v-select v-if="benef.tipo == 'Hijo(a)'" :class="styleEdad" :items="hijoList" v-model="benef.edad"
                      item-text="name" label="Edad" :rules="edadRules" return-object required filled solo
                      @change="mayorEdad()">
                    </v-select>

                  </v-col>

                  <br>
                  <br>
                  <br>
                  <br>

                  <v-col>
                    <div class="circle-container">
                      <button class="circle-button" @click.prevent="deletedBeneficiary(index)">
                        <v-icon style="color:white">mdi-minus</v-icon>
                      </button>
                    </div>
                  </v-col>

                </v-row>

              </div>

              <br> <br>

              <div class="formData">
                <v-row justify="center">

                  <v-btn class="botonAgregar" style="box-sizing: border-box; padding: 12px 24px; gap: 10px; background: #4F74E3; border: 2px solid #4F74E3; color: white;
                    border-radius: 8px; margin-top: -6%; " elevation="0" text @click="addBeneficiary()">
                    Agregar Otro
                  </v-btn>

                </v-row>

              </div>

              <br><br>

              <div v-if="screen > 800" style="max-width: 70%; margin: auto;">
                <p v-if="userData.oldAux > userData.old" class="labelInfo3">Tuvimos que actualizar el valor de tu
                  plan, ya que uno de
                  <br>
                  tus asegurados tiene un rango de edad mayor al tuyo. <br> Para revisar estas tarifas puedes verlo
                  aquí.
                </p>
                <br>
              </div>


              <div v-if="screen < 800" style="max-width: 70%; margin: auto;">
                <p v-if="userData.oldAux > userData.old" class="labelInfo3">Tuvimos que actualizar el valor de tu
                  plan, ya que uno de
                  tus asegurados tiene un rango de edad mayor al tuyo. Para revisar estas tarifas puedes verlo aquí.</p>
                <br>
              </div>

              <br>
              <div v-if="loadPlans" class="text-center labelResumen">

                <p></p>

                <label class="labelNamePlan">{{ plan.name }}</label>

                <br>

                <label v-if="plan" class="labelPrecio">{{ formatPrice(plan.values[0].priceCLP) }}*</label><label
                  class="labelSubPrecio">/{{ (plan.values[0].price).toFixed(2) }}
                  mensual</label>

                <br>

                <label class="labelSubDetalle">*Precio basado en el asegurado de mayor edad.</label>

                <v-img contain :src="infoImage" max-width="22px" max-height="22px" @click="InfoFamiliar()"
                  style="margin-left: 92%; margin-top: -6%;" />

              </div>

              <br><br>

              <div class="button-container">
                <v-btn
                  style="background-color: rgba(0, 44, 119, 1); color: white; border-radius: 12px; width: 300px; height: 50px;"
                  @click="validate()">
                  Continuar
                </v-btn>

                <br>
                <br>

                <v-btn text @click="back()">
                  Atrás
                </v-btn>

              </div>
            </v-form>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import ShieldService from "../services/shield.service";
import Titulo from "../components/Titulo.vue";
import agelist from "@/services/age-list.service.js";
import hijoList from "@/services/hijo-list.service.js";

import swal from 'sweetalert';
import CryptoJS from 'crypto-js';

import infoImage from "@/assets/images/Ayuda.png";
import NavBar from "@/components/NavBar.vue";

export default {
  name: "Step1",
  components: { Titulo, NavBar },
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,
    dialogFamiliar: false,
    firstPlanId: null,
    lengthBenef: null,
    infoImage,
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    loadPlans: false,
    stepperColor: '#00968F',
    e1: 3,
    personType: [
      "Hijo(a)",
      "Pareja",
      "Cónyuge",
    ],
    styleParentesco: null,
    styleEdad: null,
    info: [['Isapre Individual', 'Isapre Familiar', 'Fonasa Individual', 'Fonasa Familiar'], ['100%', '100%', '100%', '100%'], ['30.000', '30.000', '30.000', '30.000'], ['50', '50', '50', '50'], ['0', '24', '0', '24'], ['0', '50', '0', '50']],
    indexInfo: '0',
    plan: [],
    auxRelationship: '',
    auxRelationshipAge: '',
    userData: {
      name: null,
      surname: null,
      serialNumber: null,
      rut: null,
      typeIsapre: null,
      region: null,
      commune: null,
      comuneCode: null,
      regionCode: null,
      address: null,
      email: null,
      phoneNumber: null,
      couponId: null,
      stepId: null,
      old: null,
      dates: null,
      salud: null,
      questionOne: null,
      questionTwo: null,
      questionThree: null,
      typePlan: null,
      addressNumber: null,
      addressDepto: null,
      sonExist: false,
      arrayTypesBenef: [],
      oldAux: null,
      day: null,
      month: null,
      year: null,
    },
    servicesData: {
      stepId: null,
      contactID: null,
      type: 'create',
    },
    planData: {
      couponId: null,
      plan: null,
    },
    benefData: [
      {
        validRut: false,
        tipo: null,
        edad: null,
        name: '',
        surname: '',
        rut: '',
        days: ['Selecciona el mes'],
        months: null,
        years: null,
        date: null,
        newRut: '',
        panel: 0,
        year: null,
        month: null,
        day: null,
      }
    ],
    old: null,
    screen: window.screen.width,
    agelist: [],
    hijoList: [],
    valid: false,
    edadRules: [
      (v) => !!v || "La edad es requerida",
    ],
    parentescoRules: [
      (v) => !!v || "El parentesco es requerido",
    ],
  }),
  async mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    const userDataAux = localStorage.getItem('userData');
    const servicesDataAux = localStorage.getItem('servicesData');
    const planDataAux = localStorage.getItem('planData');
    const benefDataAux = localStorage.getItem('benefData');

    const query = this.$route.query;

    if (query.stepID) {
      const abandoned = await ShieldService.newGetStepAbandoned(query.stepID);

      this.userData = abandoned.userData;
      this.servicesData.stepId = abandoned._id;
      this.servicesData.type = 'update';
      this.planData = abandoned.planData;
      this.benefData = abandoned.benefData;

      this.mayorEdad();

      const userDataLocal = JSON.stringify(this.userData);
      const servicesDataLocal = JSON.stringify(this.servicesData);
      const planDataLocal = JSON.stringify(this.planData);
      const benefDataLocal = JSON.stringify(this.benefData);

      const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
      const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
      const encryptedPlanDataLocal = CryptoJS.AES.encrypt(planDataLocal, this.secretKey).toString();
      const encryptedBenefDataLocal = CryptoJS.AES.encrypt(benefDataLocal, this.secretKey).toString();

      localStorage.setItem('userData', encryptedUserDataLocal);
      localStorage.setItem('servicesData', encryptedServicesDataLocal);
      localStorage.setItem('planData', encryptedPlanDataLocal);
      localStorage.setItem('benefData', encryptedBenefDataLocal);

    } else if (benefDataAux) {

      const bytesBenefData = CryptoJS.AES.decrypt(benefDataAux, this.secretKey);
      const decryptedDataBenef = bytesBenefData.toString(CryptoJS.enc.Utf8);
      this.benefData = JSON.parse(decryptedDataBenef);
      // console.log("benefData: ", this.benefData);

      const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
      const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(decryptedDataUser);
      // console.log("userData: ", this.userData);

      const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
      const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
      this.servicesData = JSON.parse(decryptedServicesData);
      // console.log("servicesData: ", this.servicesData);

      const bytesPlanData = CryptoJS.AES.decrypt(planDataAux, this.secretKey);
      const decryptedDataPlan = bytesPlanData.toString(CryptoJS.enc.Utf8);
      this.planData = JSON.parse(decryptedDataPlan);
      // console.log("planData: ", this.planData);

      this.mayorEdad();

    } else {
      const benefDataLocal = JSON.stringify(this.benefData);
      const encryptedBenefDataLocal = CryptoJS.AES.encrypt(benefDataLocal, this.secretKey).toString();
      localStorage.setItem('benefData', encryptedBenefDataLocal);

      const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
      const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(decryptedDataUser);
      // console.log("userData: ", this.userData);

      const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
      const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
      this.servicesData = JSON.parse(decryptedServicesData);
      // console.log("servicesData: ", this.servicesData);

      const bytesPlanData = CryptoJS.AES.decrypt(planDataAux, this.secretKey);
      const decryptedDataPlan = bytesPlanData.toString(CryptoJS.enc.Utf8);
      this.planData = JSON.parse(decryptedDataPlan);
      // console.log("planData: ", this.planData);
    }

    if (this.screen > 800) {
      this.styleParentesco = 'labelParentesco'
      this.styleEdad = 'labelEdad'
    } else {
      this.styleParentesco = 'labelParentesco2'
      this.styleEdad = 'labelEdad2'
    }

    this.getAgeList();
    this.getHijoList();
    this.getPlan();
  },
  methods: {
    InfoFamiliar() {
      if (this.plan.name == 'Plan Familiar Isapre') {
        this.indexInfo = 1
        this.document = '/documents/ISAPRE_v3.pdf'
      }
      else if (this.plan.name == 'Plan Familiar Fonasa') {
        this.indexInfo = 3
        this.document = '/documents/FONASA_v3.pdf'
      }
      this.dialogFamiliar = true
    },
    addBeneficiary() {
      if (this.benefData.length <= 6) {
        let beneficiarioAux = {
          validRut: false,
          tipo: null,
          edad: null,
          name: '',
          surname: '',
          rut: '',
          days: ['Selecciona el mes'],
          months: null,
          years: null,
          date: null,
          newRut: '',
          panel: 0,
          year: null,
          month: null,
          day: null,
        }
        this.benefData.push(beneficiarioAux);
      } else {
        swal({
          title: "Atención!",
          text: "No puedes agregar mas beneficiarios!",
          icon: "info",
          button: "De acuerdo",
        });
      }
    },
    async deletedBeneficiary(index) {
      this.benefData.splice(index, 1);
      this.mayorEdad();
    },

    async validate() {
      if (this.$refs.form.validate()) {

        this.userData.sonExist = this.benefData.some(benef => benef.tipo === "Hijo(a)");
        this.userData.arrayTypesBenef = this.benefData.map(item => item.tipo);

        const userDataLocal = JSON.stringify(this.userData);
        const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
        localStorage.setItem('userData', encryptedUserDataLocal);

        this.planData.plan = this.plan;

        const planDataLocal = JSON.stringify(this.planData);
        const encryptedPlanDataLocal = CryptoJS.AES.encrypt(planDataLocal, this.secretKey).toString();
        localStorage.setItem('planData', encryptedPlanDataLocal);

        const benefDataLocal = JSON.stringify(this.benefData);
        const encryptedBenefDataLocal = CryptoJS.AES.encrypt(benefDataLocal, this.secretKey).toString();
        localStorage.setItem('benefData', encryptedBenefDataLocal);

        this.$router.push({ path: '/dps' });
      }
    },
    async getAgeList() {
      this.loadingAgeList = true;
      this.agelist = await agelist.getAgeList();
      this.loadingAgeList = false;
    },
    async getHijoList() {
      this.loadingHijoList = true;
      this.hijoList = await hijoList.getHijoList();
      this.loadingHijoList = false;
    },
    async getPlan() {
      this.loadPlans = false;
      var plan = await ShieldService.getPlans(this.userData.salud, this.userData.typePlan, this.userData.oldAux && this.userData.oldAux >= this.userData.old ? this.userData.oldAux : this.userData.old, this.planData.couponId);

      this.plan = plan[0];
      this.change();
      this.loadPlans = true;
    },
    async mayorEdad() {
      if (this.benefData.length != 0) {
        this.userData.typePlan = 'Familiar';

        const validBeneficiaries = this.benefData.filter(benef => benef.edad !== null);

        if (validBeneficiaries.length === 0) {
          this.userData.oldAux = this.userData.old;
        } else {
          const oldestBeneficiary = validBeneficiaries.reduce((oldest, current) => {
            return (current.edad.name > oldest.edad.name) ? current : oldest;
          }, validBeneficiaries[0]);

          if (this.benefData.length == 1) {
            this.userData.oldAux = oldestBeneficiary.edad.name;
          } else {
            this.userData.oldAux = this.userData.old < oldestBeneficiary.edad.name ? oldestBeneficiary.edad.name : this.userData.old;
          }

        }

      } else {
        this.userData.oldAux = this.userData.old;
        this.userData.typePlan = 'Individual';
      }

      this.getPlan();
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    back() {
      this.$router.push({ path: '/planes' })
    },
    async change() {

      try {

        this.planData.plan = this.plan;

        await ShieldService.newCreateStep(null, this.benefData, this.planData, this.servicesData, 'Beneficiarios');

      } catch (error) {
        console.log("Cart Fail");
      }
    }
  },
};
</script>

<style lang="scss">
.labelInfo3 {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: #232323;
  opacity: 0.75;
  margin: auto;
}

.labelPlanes {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: rgba(0, 44, 119, 1);
  padding: 1%;
  margin-bottom: 3%;
}

.labelSubPlanes {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  margin-top: -3%;
}

.labelSubPlanes2 {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  padding-bottom: 5%;
}

.labelParentesco {
  width: 257px;
  height: 57px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelParentesco2 {
  width: 130px;
  height: 57px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelEliminar {
  color: white;
  box-sizing: border-box;
  width: 35px;
  height: 35.1px;
  border: 0.5px solid #FFB600;
  margin-top: 15%
}

.labelEdad {
  width: 94px;
  height: 57px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelEdad2 {
  width: 80px;
  height: 57px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.botonAgregar {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: auto;
}

.step-date {
  max-width: 200px;
  align-items: center;
  display: flex;
  margin-left: 2rem;
  justify-content: center;

  .v-select {
    max-width: 400px;
  }
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 800px;
}

.infoIcon {
  background-image: url("~@/assets/images/Ayuda.png");
}

.labelResumen {
  box-sizing: border-box;
  width: 100%;
  max-width: 350px;
  height: 114px;
  background: #0077A0;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  margin: auto;
}

.labelNamePlan {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #FFFFFF;
}

.labelPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #FFFFFF;
}

.labelSubDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #FFFFFF;
}

.labelSubPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: rgba(255, 255, 255, 0.87);
}

.formData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.v-messages__message {
  padding-bottom: 10%;
  text-align: left !important;
  min-width: 100px;
}

.circle-container {
  // Boton de eliminar - circulo exterior
  width: 35px;
  height: 35px;
  border-radius: 20px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.1px solid #00968F;
  margin-top: 30%;
}

.circle-button {
  // Boton de eliminar - circulo Helveticaior
  width: 30px;
  height: 30px;
  border-radius: 200px;
  background-color: #00968F;
  color: white;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin: auto;
}

.circle-button v-icon {
  // Boton de eliminar - Medidas del minus
  width: 7px;
  height: 14px;
}

@media only screen and (max-width: 959.98px) {

  // Evita el mecanismo de esconder las letras del steper
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: block !important;
  }
}

.primary-override .v-application .primary {
  // Aqui sobreescribe el color del primary
  background-color: #FFB600 !important;
  border-color: #FFB600 !important;
}

.v-stepper__step__step {
  // Este es el step y sus medidas
  height: 61px;
  width: 61px;
  font-size: 24px;
  margin-top: 25%;
  background-size: cover;
  background-image: url("~@/assets/images/step2.jpg");
}

@media only screen and (max-width: 767px) {
  .v-stepper__step__step {
    width: 35px;
    height: 35px;
    font-size: 17px;
    margin-top: 40%
  }
}

.v-stepper__header {
  // Stepper header - contenedor de steper completo
  box-shadow: none;
  max-width: 70%;
  align-items: center;
  margin: auto;
}

.theme--light.v-stepper .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step {
  // boton apagado
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #8E8E8E;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
}

.v-stepper__label {
  padding: 10%;
}

@media only screen and (max-width: 767px) {
  .v-stepper__header {
    // Stepper header - contenedor de steper completo
    box-shadow: none;
    max-width: 100%;
    align-items: center;
    margin: auto;
  }
}

@media only screen and (max-width: 767px) {
  .v-stepper__step {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 15px; // el ancho
    position: relative;
  }
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper div form.v-form div div.row div.col {
  flex-basis: 0;
  flex-grow: 0;
  max-width: 100%;
}

.encabezado {
  color: #2D387C;
  font-size: 18px;
  font-weight: 600;
}

.texto {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.5);
  flex: none;
  order: 1;
  flex-grow: 0;
}

.textoAzul {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400px;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: rgba(0, 44, 119, 1);
  flex: none;
  order: 1;
  flex-grow: 0;
}
</style>