import yearData from "../utils/year-data";

export default class dateService {
  static async getYears() {
    try {
      let years = yearData;
      return years;
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al obtener los años",
      };
    }
  }
  static async getYearsFilter(yearId) {
    try {
      let years = yearData.filter(year => year.id === yearId);
      return years;
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al obtener el año por ID",
      };
    }
  }
}