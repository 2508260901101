export default [
  {
    id: "ENERO",
    name: "Enero",
    number: "01",
    days: [
      { id: "01", name: "1" },
      { id: "02", name: "2" },
      { id: "03", name: "3" },
      { id: "04", name: "4" },
      { id: "05", name: "5" },
      { id: "06", name: "6" },
      { id: "07", name: "7" },
      { id: "08", name: "8" },
      { id: "09", name: "9" },
      { id: "10", name: "10" },
      { id: "11", name: "11" },
      { id: "12", name: "12" },
      { id: "13", name: "13" },
      { id: "14", name: "14" },
      { id: "15", name: "15" },
      { id: "16", name: "16" },
      { id: "17", name: "17" },
      { id: "18", name: "18" },
      { id: "19", name: "19" },
      { id: "20", name: "20" },
      { id: "21", name: "21" },
      { id: "22", name: "22" },
      { id: "23", name: "23" },
      { id: "24", name: "24" },
      { id: "25", name: "25" },
      { id: "26", name: "26" },
      { id: "27", name: "27" },
      { id: "28", name: "28" },
      { id: "29", name: "29" },
      { id: "30", name: "30" },
      { id: "31", name: "31" },
    ],
  },
  {
    id: "FEBRERO",
    name: "Febrero",
    number: "02",
    days: [
      { id: "01", name: "1" },
      { id: "02", name: "2" },
      { id: "03", name: "3" },
      { id: "04", name: "4" },
      { id: "05", name: "5" },
      { id: "06", name: "6" },
      { id: "07", name: "7" },
      { id: "08", name: "8" },
      { id: "09", name: "9" },
      { id: "10", name: "10" },
      { id: "11", name: "11" },
      { id: "12", name: "12" },
      { id: "13", name: "13" },
      { id: "14", name: "14" },
      { id: "15", name: "15" },
      { id: "16", name: "16" },
      { id: "17", name: "17" },
      { id: "18", name: "18" },
      { id: "19", name: "19" },
      { id: "20", name: "20" },
      { id: "21", name: "21" },
      { id: "22", name: "22" },
      { id: "23", name: "23" },
      { id: "24", name: "24" },
      { id: "25", name: "25" },
      { id: "26", name: "26" },
      { id: "27", name: "27" },
      { id: "28", name: "28" },
      { id: "29", name: "29" },
    ],
  },
  {
    id: "MARZO",
    name: "Marzo",
    number: "03",
    days: [
      { id: "01", name: "1" },
      { id: "02", name: "2" },
      { id: "03", name: "3" },
      { id: "04", name: "4" },
      { id: "05", name: "5" },
      { id: "06", name: "6" },
      { id: "07", name: "7" },
      { id: "08", name: "8" },
      { id: "09", name: "9" },
      { id: "10", name: "10" },
      { id: "11", name: "11" },
      { id: "12", name: "12" },
      { id: "13", name: "13" },
      { id: "14", name: "14" },
      { id: "15", name: "15" },
      { id: "16", name: "16" },
      { id: "17", name: "17" },
      { id: "18", name: "18" },
      { id: "19", name: "19" },
      { id: "20", name: "20" },
      { id: "21", name: "21" },
      { id: "22", name: "22" },
      { id: "23", name: "23" },
      { id: "24", name: "24" },
      { id: "25", name: "25" },
      { id: "26", name: "26" },
      { id: "27", name: "27" },
      { id: "28", name: "28" },
      { id: "29", name: "29" },
      { id: "30", name: "30" },
      { id: "31", name: "31" },
    ],
  },
  {
    id: "ABRIL",
    name: "Abril",
    number: "04",
    days: [
      { id: "01", name: "1" },
      { id: "02", name: "2" },
      { id: "03", name: "3" },
      { id: "04", name: "4" },
      { id: "05", name: "5" },
      { id: "06", name: "6" },
      { id: "07", name: "7" },
      { id: "08", name: "8" },
      { id: "09", name: "9" },
      { id: "10", name: "10" },
      { id: "11", name: "11" },
      { id: "12", name: "12" },
      { id: "13", name: "13" },
      { id: "14", name: "14" },
      { id: "15", name: "15" },
      { id: "16", name: "16" },
      { id: "17", name: "17" },
      { id: "18", name: "18" },
      { id: "19", name: "19" },
      { id: "20", name: "20" },
      { id: "21", name: "21" },
      { id: "22", name: "22" },
      { id: "23", name: "23" },
      { id: "24", name: "24" },
      { id: "25", name: "25" },
      { id: "26", name: "26" },
      { id: "27", name: "27" },
      { id: "28", name: "28" },
      { id: "29", name: "29" },
      { id: "30", name: "30" },
    ],
  },
  {
    id: "MAYO",
    name: "Mayo",
    number: "05",
    days: [
      { id: "01", name: "1" },
      { id: "02", name: "2" },
      { id: "03", name: "3" },
      { id: "04", name: "4" },
      { id: "05", name: "5" },
      { id: "06", name: "6" },
      { id: "07", name: "7" },
      { id: "08", name: "8" },
      { id: "09", name: "9" },
      { id: "10", name: "10" },
      { id: "11", name: "11" },
      { id: "12", name: "12" },
      { id: "13", name: "13" },
      { id: "14", name: "14" },
      { id: "15", name: "15" },
      { id: "16", name: "16" },
      { id: "17", name: "17" },
      { id: "18", name: "18" },
      { id: "19", name: "19" },
      { id: "20", name: "20" },
      { id: "21", name: "21" },
      { id: "22", name: "22" },
      { id: "23", name: "23" },
      { id: "24", name: "24" },
      { id: "25", name: "25" },
      { id: "26", name: "26" },
      { id: "27", name: "27" },
      { id: "28", name: "28" },
      { id: "29", name: "29" },
      { id: "30", name: "30" },
      { id: "31", name: "31" },
    ],
  },
  {
    id: "JUNIO",
    name: "Junio",
    number: "06",
    days: [
      { id: "01", name: "1" },
      { id: "02", name: "2" },
      { id: "03", name: "3" },
      { id: "04", name: "4" },
      { id: "05", name: "5" },
      { id: "06", name: "6" },
      { id: "07", name: "7" },
      { id: "08", name: "8" },
      { id: "09", name: "9" },
      { id: "10", name: "10" },
      { id: "11", name: "11" },
      { id: "12", name: "12" },
      { id: "13", name: "13" },
      { id: "14", name: "14" },
      { id: "15", name: "15" },
      { id: "16", name: "16" },
      { id: "17", name: "17" },
      { id: "18", name: "18" },
      { id: "19", name: "19" },
      { id: "20", name: "20" },
      { id: "21", name: "21" },
      { id: "22", name: "22" },
      { id: "23", name: "23" },
      { id: "24", name: "24" },
      { id: "25", name: "25" },
      { id: "26", name: "26" },
      { id: "27", name: "27" },
      { id: "28", name: "28" },
      { id: "29", name: "29" },
      { id: "30", name: "30" },
    ],
  },
  {
    id: "JULIO",
    name: "Julio",
    number: "07",
    days: [
      { id: "01", name: "1" },
      { id: "02", name: "2" },
      { id: "03", name: "3" },
      { id: "04", name: "4" },
      { id: "05", name: "5" },
      { id: "06", name: "6" },
      { id: "07", name: "7" },
      { id: "08", name: "8" },
      { id: "09", name: "9" },
      { id: "10", name: "10" },
      { id: "11", name: "11" },
      { id: "12", name: "12" },
      { id: "13", name: "13" },
      { id: "14", name: "14" },
      { id: "15", name: "15" },
      { id: "16", name: "16" },
      { id: "17", name: "17" },
      { id: "18", name: "18" },
      { id: "19", name: "19" },
      { id: "20", name: "20" },
      { id: "21", name: "21" },
      { id: "22", name: "22" },
      { id: "23", name: "23" },
      { id: "24", name: "24" },
      { id: "25", name: "25" },
      { id: "26", name: "26" },
      { id: "27", name: "27" },
      { id: "28", name: "28" },
      { id: "29", name: "29" },
      { id: "30", name: "30" },
      { id: "31", name: "31" },
    ],
  },
  {
    id: "AGOSTO",
    name: "Agosto",
    number: "08",
    days: [
      { id: "01", name: "1" },
      { id: "02", name: "2" },
      { id: "03", name: "3" },
      { id: "04", name: "4" },
      { id: "05", name: "5" },
      { id: "06", name: "6" },
      { id: "07", name: "7" },
      { id: "08", name: "8" },
      { id: "09", name: "9" },
      { id: "10", name: "10" },
      { id: "11", name: "11" },
      { id: "12", name: "12" },
      { id: "13", name: "13" },
      { id: "14", name: "14" },
      { id: "15", name: "15" },
      { id: "16", name: "16" },
      { id: "17", name: "17" },
      { id: "18", name: "18" },
      { id: "19", name: "19" },
      { id: "20", name: "20" },
      { id: "21", name: "21" },
      { id: "22", name: "22" },
      { id: "23", name: "23" },
      { id: "24", name: "24" },
      { id: "25", name: "25" },
      { id: "26", name: "26" },
      { id: "27", name: "27" },
      { id: "28", name: "28" },
      { id: "29", name: "29" },
      { id: "30", name: "30" },
      { id: "31", name: "31" },
    ],
  },
  {
    id: "SEPTIEMBRE",
    name: "Septiembre",
    number: "09",
    days: [ 
      { id: "01", name: "1" },
      { id: "02", name: "2" },
      { id: "03", name: "3" },
      { id: "04", name: "4" },
      { id: "05", name: "5" },
      { id: "06", name: "6" },
      { id: "07", name: "7" },
      { id: "08", name: "8" },
      { id: "09", name: "9" },
      { id: "10", name: "10" },
      { id: "11", name: "11" },
      { id: "12", name: "12" },
      { id: "13", name: "13" },
      { id: "14", name: "14" },
      { id: "15", name: "15" },
      { id: "16", name: "16" },
      { id: "17", name: "17" },
      { id: "18", name: "18" },
      { id: "19", name: "19" },
      { id: "20", name: "20" },
      { id: "21", name: "21" },
      { id: "22", name: "22" },
      { id: "23", name: "23" },
      { id: "24", name: "24" },
      { id: "25", name: "25" },
      { id: "26", name: "26" },
      { id: "27", name: "27" },
      { id: "28", name: "28" },
      { id: "29", name: "29" },
      { id: "30", name: "30" },
    ],
  },
  {
    id: "OCTUBRE",
    name: "Octubre",
    number: "10",
    days: [
      { id: "01", name: "1" },
      { id: "02", name: "2" },
      { id: "03", name: "3" },
      { id: "04", name: "4" },
      { id: "05", name: "5" },
      { id: "06", name: "6" },
      { id: "07", name: "7" },
      { id: "08", name: "8" },
      { id: "09", name: "9" },
      { id: "10", name: "10" },
      { id: "11", name: "11" },
      { id: "12", name: "12" },
      { id: "13", name: "13" },
      { id: "14", name: "14" },
      { id: "15", name: "15" },
      { id: "16", name: "16" },
      { id: "17", name: "17" },
      { id: "18", name: "18" },
      { id: "19", name: "19" },
      { id: "20", name: "20" },
      { id: "21", name: "21" },
      { id: "22", name: "22" },
      { id: "23", name: "23" },
      { id: "24", name: "24" },
      { id: "25", name: "25" },
      { id: "26", name: "26" },
      { id: "27", name: "27" },
      { id: "28", name: "28" },
      { id: "29", name: "29" },
      { id: "30", name: "30" },
      { id: "31", name: "31" },
    ],
  },
  {
    id: "NOVIEMBRE",
    name: "Noviembre",
    number: "11",
    days: [
      { id: "01", name: "1" },
      { id: "02", name: "2" },
      { id: "03", name: "3" },
      { id: "04", name: "4" },
      { id: "05", name: "5" },
      { id: "06", name: "6" },
      { id: "07", name: "7" },
      { id: "08", name: "8" },
      { id: "09", name: "9" },
      { id: "10", name: "10" },
      { id: "11", name: "11" },
      { id: "12", name: "12" },
      { id: "13", name: "13" },
      { id: "14", name: "14" },
      { id: "15", name: "15" },
      { id: "16", name: "16" },
      { id: "17", name: "17" },
      { id: "18", name: "18" },
      { id: "19", name: "19" },
      { id: "20", name: "20" },
      { id: "21", name: "21" },
      { id: "22", name: "22" },
      { id: "23", name: "23" },
      { id: "24", name: "24" },
      { id: "25", name: "25" },
      { id: "26", name: "26" },
      { id: "27", name: "27" },
      { id: "28", name: "28" },
      { id: "29", name: "29" },
      { id: "30", name: "30" },
    ],
  },
  {
    id: "DICIEMBRE",
    name: "Diciembre",
    number: "12",
    days: [
      { id: "01", name: "1" },
      { id: "02", name: "2" },
      { id: "03", name: "3" },
      { id: "04", name: "4" },
      { id: "05", name: "5" },
      { id: "06", name: "6" },
      { id: "07", name: "7" },
      { id: "08", name: "8" },
      { id: "09", name: "9" },
      { id: "10", name: "10" },
      { id: "11", name: "11" },
      { id: "12", name: "12" },
      { id: "13", name: "13" },
      { id: "14", name: "14" },
      { id: "15", name: "15" },
      { id: "16", name: "16" },
      { id: "17", name: "17" },
      { id: "18", name: "18" },
      { id: "19", name: "19" },
      { id: "20", name: "20" },
      { id: "21", name: "21" },
      { id: "22", name: "22" },
      { id: "23", name: "23" },
      { id: "24", name: "24" },
      { id: "25", name: "25" },
      { id: "26", name: "26" },
      { id: "27", name: "27" },
      { id: "28", name: "28" },
      { id: "29", name: "29" },
      { id: "30", name: "30" },
      { id: "31", name: "31" },
    ],
  },
];