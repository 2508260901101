<template>
  <div>

    <v-row justify="center">
      <v-dialog v-model="dialogFamiliar" width="1000px">
        <v-card>
          <v-card-text style="text-align: justify;">
            <div>
              <v-btn icon @click="dialogFamiliar = false" style="float: right;">
                <v-icon>mdi-close</v-icon>
              </v-btn>

              <br>
              <br>


              <div style="background: #002C77; border-radius: 9px; text-align: center;">
                <div style="padding: 2%;">
                  <label style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 22px; line-height: 18px; letter-spacing: 0.02em;
                        color: white; flex: none; order: 1; flex-grow: 0;">Estas son las coberturas y protecciones
                    del </label>
                  <label style="font-family: Helvetica;font-style: normal;  font-weight: 700; font-size: 22px; line-height: 18px; letter-spacing: 0.02em;
                        color: white; flex: none; order: 1; flex-grow: 0;">Plan<label
                      style="font-family: Helvetica; font-style: normal; font-weight: 700; 
                        font-size: 22px; line-height: 18px; letter-spacing: 0.02em; color: white; flex: none; order: 1; flex-grow: 0; text-transform: uppercase; ">
                      {{ info[0][indexInfo] }}</label> del <br> Seguro Oncológico de Southbridge.
                  </label>
                </div>


              </div>


              <br>
              <br>


              <div style="padding-left: 9%; ">
                <label class=" encabezado"> • {{ info[1][indexInfo] }} Reembolso Gastos médicos tratamiento
                  Oncológico familiar.</label>
              </div>


              <br>
              <div style="padding-left: 9%; padding-right: 7%; margin-bottom: 10px;">
                <label class="texto">
                  En caso de que te diagnostiquen algún tipo de cáncer, podrás iniciar el tratamiento en la Red de Salud UC CHRISTUS donde reembolsaremos
                   <label class="textoAzul"> hasta el {{ info[1][indexInfo] }} de los gastos del tratamiento</label> por 
                   <label class="textoAzul">hasta UF {{ info[3][indexInfo] }} (más de 1.000 millones de pesos) </label>
                   n el caso que tengas menos de 69 años al momento de tu diagnóstico y
                   <label class="textoAzul">UF {{ info[4][indexInfo] }} (más de 180 millones de pesos) en caso que tengas 70 o más años </label>
                   al momento de tu diagnóstico.
                  <br> </label>
              </div>
              <div style="text-align: center; ">
                <label class="texto"> Si quieres revisar más en detalle los <br> gastos que cubre </label><a
                  :href="document" target="_blank"> <br>
                  <div style="border: solid 0.75px; margin-left: 43%; margin-right: 43%; border-radius: 5px;  ">haz clic
                    aquí</div>
                </a>
              </div>

              <br>
              <br>

              <div style="padding-left: 9%; ">
                <label class="encabezado"> • Indemnización Libre disposición ante diagnóstico positivo.</label>
              </div>

              <br>

              <div style="padding-left: 9%; padding-right: 7%;">
                <label class="texto">En caso de que te diagnostiquen a tí o algún integrante de tu familia que esté contigo en el seguro algún tipo de cáncer, podrás solicitar a la compañía
                  <label class="textoAzul">UF {{ info[5][indexInfo] }} de indemnización</label> en caso que el asegurado tenga menos de 69 años al momento de su diagnóstico o
                  <label class="textoAzul"> UF {{ info[6][indexInfo] }} si tienen más de 70 años al momento de su diagnóstico</label>, para que lo usen en lo que quieran, como alojamientos y traslados para el tratamiento o comprarse lo que necesiten.
                  <label class="textoAzul">
                    Sin preguntas</label>.</label>
              </div>

              <br>

              <div style="padding-left: 9%; padding-right: 7%;">
                <label class="texto">Esto pueden solicitarlo con la confirmación de <label class="textoAzul">UC
                    CHRISTUS</label> de
                  contratación y hasta de un diagnóstico positivo de cáncer.</label>
              </div>
              <br>
              <br>

              <div style=" padding-left: 9%;">
                <label class=" encabezado"> • Agrega a integrantes de tu familia sin cambiar el precio del
                  seguro.</label>
              </div>



              <br>

              <div style="padding-left: 9%; padding-right: 7%;">
                <label class="texto">El plan {{ info[0][indexInfo] }} permite que agregues a tu <label
                    class="textoAzul">cónyuge/pareja/conviviente </label>
                  y a <label class="textoAzul">todos </label>tus y sus <label class="textoAzul">hijos e hijas
                  </label>(hasta
                  24 años de edad) por el <label class="textoAzul">mismo valor del plan</label>, y cada uno con la
                  protección de UF 30.000 de tratamiento y con las UF {{ info[5][indexInfo] }} de indemnización ante
                  diagnostico positivo. </label>
              </div>

              <br>
              <br>

              <div style="padding-left: 9%;">
                <label class=" encabezado"> • Protección desde el día que contratas</label>
              </div>


              <br>
              <div style="padding-left: 9%; padding-right: 7%;">
                <label class="texto">La protección de este seguro comienza desde el <label class="textoAzul">día que
                    contratas</label>.
                  Olvídate de carencias o semanas para que comience a protegerte. </label>
              </div>



            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn style="box-sizing: border-box; padding: 12px 24px; gap: 10px; background: #FFB600; border: 2px solid #FFB600;
                    border-radius: 8px; margin-top: -6%; font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 14px; line-height: 17px;
                    letter-spacing: 0.02em; color: white; flex: none; order: 0; flex-grow: 0; margin:auto;"
              elevation="0" text @click="dialogFamiliar = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogIndividual" width="1000px">
        <v-card>
          <v-card-text style="text-align: justify;">
            <div>
              <v-btn icon @click="dialogIndividual = false" style="float: right;">
                <v-icon>mdi-close</v-icon>
              </v-btn>

              <br>
              <br>
              <div style="background: #002C77; border-radius: 9px; text-align: center;">
                <div style="padding: 2%;">
                  <label style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 22px; line-height: 18px; letter-spacing: 0.02em;
                      color: white; flex: none; order: 1; flex-grow: 0;">Estas son las coberturas y protecciones
                    del </label>
                  <label style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 22px; line-height: 18px; letter-spacing: 0.02em;
                      color: white; flex: none; order: 1; flex-grow: 0;">Plan<label
                      style="font-family: Helvetica; font-style: normal; font-weight: 700; 
                      font-size: 22px; line-height: 18px; letter-spacing: 0.02em; color: white; flex: none; order: 1; flex-grow: 0; text-transform: uppercase;">
                      {{ info[0][indexInfo] }}</label> <br> del Seguro Oncológico de Southbridge.
                  </label>
                </div>

              </div>


              <br>
              <br>

              <div style="padding-left: 9%; ">
                <label class="encabezado"> • {{ info[1][indexInfo] }} Reembolso Gastos médicos tratamiento
                  Oncológico individual</label>
              </div>


              <br>
              <div style="padding-left: 9%; padding-right: 7%;  margin-bottom: 10px">
                <label class="texto">En caso de que te diagnostiquen algún tipo de cáncer, podrás iniciar el tratamiento en la Red de Salud UC CHRISTUS donde reembolsaremos
                   <label class="textoAzul">hasta el {{ info[1][indexInfo] }} de los gastos del tratamiento</label> por
                   <label class="textoAzul">hasta UF {{ info[3][indexInfo] }} (más de 1.000 millones de pesos) </label>
                   en el caso que tengas menos de 69 años al momento de tu diagnóstico y
                   <label class="textoAzul"> UF {{ info[4][indexInfo] }} (más de 180 millones de pesos) en caso que tengas 70 o más años</label>
                   al momento de tu diagnóstico. <br></label>
              </div>
              <div style="text-align: center;">
                <label class="texto">
                  Si quieres revisar más en detalle los <br> gastos que cubre<a :href="document" target="_blank"> <br>
                    <div style="border: solid 0.75px; margin-left: 43%; margin-right: 43%; border-radius: 5px;">haz clic
                      aquí</div>
                  </a>
                </label>
              </div>

              <br>
              <br>

              <div style="padding-left: 9%; ">
                <label class="encabezado"> • Indemnización Libre disposición ante
                  diagnóstico positivo.</label>
              </div>

              <br>

              <div style="padding-left: 9%; padding-right: 7%;">
                <label class="texto">En caso de que te diagnostiquen algún tipo de cáncer, podrás solicitar a la compañía
                  <label class="textoAzul">{{ info[5][indexInfo] }} UF de indemnización</label> en caso que tengas menos de 69 años al momento de tu diagnostico o
                  <label class="textoAzul"> UF {{ info[6][indexInfo] }} si tienes más de 70 años al momento de tu diagnóstico</label>
                  , para que lo uses en lo que quieras, como alojamientos y traslados para tu tratamiento o comprarte lo que necesites.
                  <label class="textoAzul">
                    Sin preguntas</label>.</label>
              </div>

              <br>
              <div style="padding-left: 9%; padding-right: 7%;">
                <label class="texto">Esto puedes solicitarlo con la confirmación de <label class="textoAzul">UC
                    CHRISTUS</label> de un diagnóstico positivo de cáncer.</label>
              </div>

              <br>
              <br>
              <div style="padding-left: 9%; padding-right: 7%;">
                <label class="encabezado"> • Protección desde el día que contratas</label>
              </div>


              <br>
              <div style="padding-left: 9%; padding-right: 7%;">
                <label class="texto">La protección de este seguro comienza desde el <label class="textoAzul">día que
                    contratas</label>. Olvídate de carencias o semanas para que comience a protegerte. </label>
              </div>

            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn style="box-sizing: border-box; padding: 12px 24px; gap: 10px; background: #FFB600; border: 2px solid #FFB600;
                    border-radius: 8px; margin-top: -6%; font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 14px; line-height: 17px;
                    letter-spacing: 0.02em; color: white; flex: none; order: 0; flex-grow: 0; margin:auto;"
              elevation="0" text @click="dialogIndividual = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-row>

    <div v-if="loadingPlans == false">

      <div class="plan" v-if="planData.name == 'Plan Familiar ISAPRE' || planData.name == 'Plan Familiar FONASA'">

        <v-col class="text-center">

          <div class="planFamiliarRecomendado">

            <label class="planFamiliarConveniente">El Más conveniente</label>

          </div>

          <p class="planFamiliarName">{{ planData.name }}</p>

          <p></p>

          <p class="planFamiliarPrecio">{{ formatPrice(planData.values[0].priceCLP) }}</p>

          <p class="planFamiliarSubPrecio">/ UF {{ (planData.values[0].price).toFixed(2) }} mensual</p>

          <label class="planFamiliarSubDetalle">*Precio basado en la edad del contratante.</label>

          <hr class="planFamiliarLinea">

          <br>

          <p class="planFamiliarProtege"><b>¡Asegura hasta 7 familiares sin costo adicional!</b></p>

          <p></p>

          <label class="planFamiliarCaracteristicas">Características</label>

          <p></p>

          <li class="planFamiliarBeneficios">{{ planData.coverage[0].description }}</li>
          <li class="planFamiliarBeneficios">UF {{ ufFormat(planData.coverage[1].amount) }} {{
            planData.coverage[1].description }}</li>
          <li class="planFamiliarBeneficios">UF {{ ufFormat(planData.coverage[2].amount) }} {{
            planData.coverage[2].description }}</li>
          <li class="planFamiliarBeneficios">Agrega a tu cónyuge, pareja, conviviente y a todos tus hijos por un único
            precio.</li>

          <p></p>

          <hr class="planFamiliarLinea">

          <div class="plan-info">

            <button>

              <b>
                <u class="planFamiliarVerDetalle" elevation="0" @click="InfoFamiliar()">
                  VER DETALLE
                </u>
              </b>

            </button>

          </div>

        </v-col>

        <br>

        <v-btn class="elegirPlan" style="background-color: #FFB600;" @click="validate()">
          <label style="color: white;">Elegir plan</label>

        </v-btn>

        <br>

      </div>

      <div class="plan2" v-if="planData.name == 'Plan Individual ISAPRE' || planData.name == 'Plan Individual FONASA'">

        <v-col class="text-center">

          <br>

          <p class="planIndividualName">{{ planData.name }}</p>

          <p></p>

          <p class="planIndividualPrecio">{{ formatPrice(planData.values[0].priceCLP) }}</p>

          <p class="planIndividualSubPrecio">/ UF {{ (planData.values[0].price).toFixed(2) }} mensual</p>

          <label class="planIndividualSubDetalle">*Precio basado en la edad del contratante.</label>
          <p></p>

          <hr class="planIndividualLinea">

          <br>

          <p class="planIndividualProtege"> <b>Protege tu salud y tu tranquilidad financiera</b></p>

          <p></p>

          <label class="planIndividualCaracteristicas">Características</label>

          <br>
          <br>

          <li class="planIndividualBeneficios">{{ planData.coverage[0].description }}
          </li>
          <p></p>
          <li class="planIndividualBeneficios">UF {{ ufFormat(planData.coverage[1].amount) }} {{
            planData.coverage[1].description }}
          </li>
          <p></p>
          <li class="planIndividualBeneficios">UF {{ ufFormat(planData.coverage[2].amount) }} {{
            planData.coverage[2].description }}
          </li>
          <p style="margin-top: 13%;"></p>

          <br>
          <br>

          <hr class="planIndividualLinea">

          <div class="plan-info">

            <button>

              <b>
                <u class="planIndividualVerDetalle" elevation="0" @click="InfoIndividual()">
                  VER DETALLE
                </u>
              </b>


            </button>

          </div>

        </v-col>

        <br>

        <v-btn class="elegirPlan" style="background-color: #FFB600;" @click="validate()">
          <label style="color: white;">Elegir plan</label>
        </v-btn>

        <br>

      </div>

    </div>

  </div>
</template>

<script>
import LibreEleccion from "../assets/images/libreeleccion.png"
import ShieldService from "../services/shield.service";

import CryptoJS from 'crypto-js';

export default {
  name: "Plan",
  props: ["planData", "index", "userData", "loadingPlans", "planDataAux", "servicesData"],
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,
    stepId: null,
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    formatterUF: new Intl.NumberFormat("es-CL"),
    LibreEleccion,
    info: [['Isapre Individual', 'Isapre Familiar', 'Fonasa Individual', 'Fonasa Familiar'], ['100%', '100%', '100%', '100%'],['50', '50', '50', '50'], ['30.000', '30.000', '30.000', '30.000'], ['5.000', '5.000', '5.000', '5.000'],['50', '50', '50', '50'], ['10', '10', '10', '10'], ['0', '30.000', '0', '30.000'], ['0', '50', '0', '50']],
    indexInfo: '0',
    dialogIndividual: false,
    dialogFamiliar: false,
    document: null,
  }),
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  methods: {
    ufFormat(price) {
      return this.formatterUF.format(price);
    },
    InfoFamiliar() {
      if (this.planData.name == 'Plan Familiar ISAPRE') {
        this.indexInfo = 1
        this.document = '/documents/ISAPRE_v3.pdf'
      }
      else if (this.planData.name == 'Plan Familiar FONASA') {
        this.indexInfo = 3
        this.document = '/documents/FONASA_v3.pdf'
      }
      this.dialogFamiliar = true
    },
    InfoIndividual() {
      if (this.planData.name == 'Plan Individual ISAPRE') {
        this.indexInfo = 0
        this.document = '/documents/ISAPRE_v3.pdf'
      }
      else if (this.planData.name == 'Plan Individual FONASA') {
        this.indexInfo = 2
        this.document = '/documents/FONASA_v3.pdf'
      }
      this.dialogIndividual = true
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    ufchange(uf) {
      let nn = uf.toString();
      let new_uf = nn.replace('.', ',')
      return new_uf;
    },
    async validate() {

      this.planDataAux.plan = this.planData;

      const planDataLocal = JSON.stringify(this.planDataAux);
      const encryptedPlanDataLocal = CryptoJS.AES.encrypt(planDataLocal, this.secretKey).toString();
      localStorage.setItem('planData', encryptedPlanDataLocal);

      var namePlanAux = this.planData.name.split(' ');
      this.userData.typePlan = namePlanAux.includes('Familiar') ? 'Familiar' : 'Individual';

      const userDataLocal = JSON.stringify(this.userData);
      const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
      localStorage.setItem('userData', encryptedUserDataLocal);

      try {

        await ShieldService.newCreateStep(this.userData, null, this.planDataAux, this.servicesData, 'Planes');

      } catch (error) {
        console.log("Cart Fail");
      }

      if (namePlanAux.includes('Familiar')) {
        this.$router.push({ path: '/beneficiarios' })
      } else if (!namePlanAux.includes('Familiar')) {
        this.$router.push({ path: '/dps' })
      }

    },
    parseNumber(n) {
      return new Intl.NumberFormat().format(n);
    },
  },
};
</script>

<style lang="scss" scoped>
.planFamiliarRecomendado {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 25px;
  gap: 8px;
  width: 171px;
  height: 22px;
  background: #FFB600;
  border-radius: 5px;
  margin: auto;
  margin-top: 5%;
}

.planFamiliarConveniente {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #585858;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.planFamiliarName {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #FFFFFF;
  width: 200px;
  margin: auto;
  margin-top: 5%;
}

.planFamiliarPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  margin: auto;
}

.planFamiliarSubPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}

.planFamiliarSubDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: rgba(255, 255, 255, 0.85);
}

.planFamiliarLinea {
  width: 214px;
  height: 0px;
  border: 0.946207px solid #FFFFFF;
  margin: auto;
}

.planFamiliarProtege {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  width: 250px;
  justify-content: center;
  margin: auto;
}

.planFamiliarCaracteristicas {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 128.02%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.planFamiliarBeneficios {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 250px;
  text-align: justify;
  margin: 0 auto;
  margin-bottom: 10px;
  text-indent: -22px;
}


.planFamiliarVerDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 4px 8px 24px rgba(251, 148, 0, 0.25);
}

.elegirPlan {
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  width: 238px;
  height: 37px;
  top: calc(50% - 39px/2 + 100px);
  background: #FFB600;
  border: 2px solid #FFB600;
  border-radius: 8px;
}

.planIndividualName {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #343497;
  width: 200px;
  margin: auto;
  margin-top: 9%;
}

.planIndividualPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #343497;
  margin: auto;
}

.planIndividualSubPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: rgba(53, 53, 53, 0.65);
}

.planIndividualSubDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: rgba(58, 58, 58, 0.85);
}

.planIndividualLinea {
  width: 214px;
  height: 0px;
  border: 0.946207px solid rgba(0, 44, 119, 1);
  margin: auto;
  margin-top: -7%;
}

.planIndividualProtege {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;
  color: #3C3C3C;
  width: 200px;
  justify-content: center;
  margin: auto;
  
}

.planIndividualCaracteristicas {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 128.02%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #3C3C3C;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.planIndividualBeneficios {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #3C3C3C;
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 250px;
  text-align: justify;
  margin: 0 auto;
  margin-bottom: 10px;
  text-indent: -22px;
}

.planIndividualVerDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #4040A3;
  text-shadow: 4px 8px 24px rgba(251, 148, 0, 0.25);
}

.texto {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.5);
  flex: none;
  order: 1;
  flex-grow: 0;

}

.textoAzul {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400px;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: rgba(0, 44, 119, 1);
  flex: none;
  order: 1;
  flex-grow: 0;
}

.encabezado {
  color: #002C77;
  font-size: 18px;
  font-weight: 600;
}

.plan-info {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plan {
  background: linear-gradient(0deg, #0077A0, #0077A0), #D8D8D8;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem;
  align-items: center;
  padding: 0.75rem;
  border-radius: 10px 10px 10px 10px;
  width: 326px;
}

.plan2 {
  background: linear-gradient(0deg, #F4F4F4, #F4F4F4), #D8D8D8;
  ;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem;
  align-items: center;
  padding: 0.75rem;
  border-radius: 10px 10px 10px 10px;
  width: 326px;
}
</style>
