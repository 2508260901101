import axios from "axios";
const { VUE_APP_SHIELD_URL, VUE_APP_SPONSOR } = process.env;

export default class ShieldService {

  static async newCreateStep(userData, benefData, planData, servicesData, step) {
    try {

      let url;
      const jsonData = { userData, benefData, planData, servicesData, step, sponsor: VUE_APP_SPONSOR };

      if (servicesData.type == 'create') {
        url = '/step/newCreate';
      } else if (servicesData.type == 'update') {
        url = '/step/newUpdate';
      }

      const carroResponse = await axios.post(
        `${VUE_APP_SHIELD_URL}${url}`,
        jsonData
      );

      return carroResponse;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error en el servicio Stepers",
      };
    }
  }

  static async newGetStepAbandoned(stepId) {

    const shieldRes = await axios.get(
      `${VUE_APP_SHIELD_URL}/step/newGetAbandoned/${stepId}`
    );
    if (shieldRes.data.status === "ok") return shieldRes.data.data;
    else throw shieldRes.data;
  }

  //Lógica para redireccionar los planes cuando tiene un código de descuento
  static async getPlans(forecast, benef, age, code) {

    //Obtener los parámetros para buscar planes
    var cantBenf;
    var category;
    var maxAge = age;

    if (forecast == "Isapre" && benef == "Individual" && maxAge >= 18 && maxAge <= 39) {
      category = "ISAPRE01"; //Ver Tarifario
      cantBenf = "Plan Individual Isapre";
    } else if (forecast == "Isapre" && benef == "Individual" && maxAge >= 40 && maxAge <= 49) {
      category = "ISAPRE02";
      cantBenf = "Plan Individual Isapre";
    } else if (forecast == "Isapre" && benef == "Individual" && maxAge >= 50 && maxAge <= 59) {
      category = "ISAPRE03";
      cantBenf = "Plan Individual Isapre";
    } else if (forecast == "Isapre" && benef == "Individual" && maxAge >= 60 && maxAge <= 69) {
      category = "ISAPRE04";
      cantBenf = "Plan Individual Isapre";
    } else if (forecast == "Isapre" && benef == "Individual" && maxAge >= 70 && maxAge <= 79) {
      category = "ISAPRE05";
      cantBenf = "Plan Individual Isapre";
    } else if (forecast == "Isapre" && benef == "Individual" && maxAge >= 80) {
      category = "ISAPRE06";
      cantBenf = "Plan Individual Isapre";
    }
    else if (forecast == "Isapre" && benef == "Familiar" && maxAge >= 0 && maxAge <= 39) {
      category = "ISAPRE07";
      cantBenf = "Plan Familiar Isapre";
    } else if (forecast == "Isapre" && benef == "Familiar" && maxAge >= 40 && maxAge <= 49) {
      category = "ISAPRE08";
      cantBenf = "Plan Familiar Isapre";
    } else if (forecast == "Isapre" && benef == "Familiar" && maxAge >= 50 && maxAge <= 59) {
      category = "ISAPRE09";
      cantBenf = "Plan Familiar Isapre";
    } else if (forecast == "Isapre" && benef == "Familiar" && maxAge >= 60 && maxAge <= 69) {
      category = "ISAPRE10";
      cantBenf = "Plan Familiar Isapre";
    } else if (forecast == "Isapre" && benef == "Familiar" && maxAge >= 70 && maxAge <= 79) {
      category = "ISAPRE11";
      cantBenf = "Plan Familiar Isapre";
    } else if (forecast == "Isapre" && benef == "Familiar" && maxAge >= 80) {
      category = "ISAPRE12";
      cantBenf = "Plan Familiar Isapre";
    }
    else if (forecast == "Fonasa" && benef == "Individual" && maxAge >= 18 && maxAge <= 39) {
      //Inicio Individual Fonasa
      category = "FONASA01";
      cantBenf = "Plan Individual Fonasa";
    } else if (forecast == "Fonasa" && benef == "Individual" && maxAge >= 40 && maxAge <= 49) {
      category = "FONASA02";
      cantBenf = "Plan Individual Fonasa";
    } else if (forecast == "Fonasa" && benef == "Individual" && maxAge >= 50 && maxAge <= 59) {
      category = "FONASA03";
      cantBenf = "Plan Individual Fonasa";
    } else if (forecast == "Fonasa" && benef == "Individual" && maxAge >= 60 && maxAge <= 69) {
      category = "FONASA04";
      cantBenf = "Plan Individual Fonasa";
    } else if (forecast == "Fonasa" && benef == "Individual" && maxAge >= 70 && maxAge <= 79) {
      category = "FONASA05";
      cantBenf = "Plan Individual Fonasa";
    } else if (forecast == "Fonasa" && benef == "Individual" && maxAge >= 80) {
      category = "FONASA06";
      cantBenf = "Plan Individual Fonasa";
    }
    else if (forecast == "Fonasa" && benef == "Familiar" && maxAge >= 0 && maxAge <= 39) {
      category = "FONASA07";
      cantBenf = "Plan Familiar Fonasa";
    } else if (forecast == "Fonasa" && benef == "Familiar" && maxAge >= 40 && maxAge <= 49) {
      category = "FONASA08";
      cantBenf = "Plan Familiar Fonasa";
    } else if (forecast == "Fonasa" && benef == "Familiar" && maxAge >= 50 && maxAge <= 59) {
      category = "FONASA09";
      cantBenf = "Plan Familiar Fonasa";
    } else if (forecast == "Fonasa" && benef == "Familiar" && maxAge >= 60 && maxAge <= 69) {
      category = "FONASA10";
      cantBenf = "Plan Familiar Fonasa";
    } else if (forecast == "Fonasa" && benef == "Familiar" && maxAge >= 70 && maxAge <= 79) {
      category = "FONASA11";
      cantBenf = "Plan Familiar Fonasa";
    } else if (forecast == "Fonasa" && benef == "Familiar" && maxAge >= 80) {
      category = "FONASA12";
      cantBenf = "Plan Familiar Fonasa";
    }
    try {
      var url = `${VUE_APP_SHIELD_URL}/oncologico`
      var params = "";

      if (code) {
        params = url + `?sponsor=${VUE_APP_SPONSOR}&category=${category}&cantBenf=${cantBenf}&age=${maxAge}&code=${code}`
      } else {
        params = url + `?sponsor=${VUE_APP_SPONSOR}&category=${category}&cantBenf=${cantBenf}&age=${maxAge}`
      }
      //Llamada al controlador oncologico en SHIELD, para la busqueda de planes
      const shieldRes = await axios.get(
        params
      );

      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al obtener los planes",
      };
    }
  }

  static async getPeople(personID) {

    try {

      const shieldRes = await axios.get(
        `${VUE_APP_SHIELD_URL}/person/get/${personID}`,
      );

      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al obtener los planes",
      };
    }
  }

  static async createInsurance(person, beneficiaries, plan, stepId) {

    person.forecast = person.salud
    person.birthdate = person.dates

    //Generar Array ordenado de Beneficiarios
    var BenefData = [];

    if (person.typePlan == 'Familiar') {
      beneficiaries.forEach((benef) => {
        BenefData.push({
          name: benef.name,
          surname: benef.surname,
          rut: benef.rut,
          parentesco: benef.tipo,
          fechaNacimiento: '' + benef.day.id + '-' + benef.month.number + '-' + benef.year.id,
        });
      });
    }

    person.BenefData = BenefData;

    //Se genera la petición a SIELD para generar la Insurance y conección a Instanda
    const jsonData = { person, planId: plan._id, stepId: stepId };

    const shieldRes = await axios.post(
      // `${VUE_APP_SHIELD_URL}/oncologico`,
      //aca hay que cambiar algo al parecer o al menos bipassear el proceso de insurance
      `${VUE_APP_SHIELD_URL}/oncologico/createWithRunWayPayment`,
      jsonData
    );
    if (shieldRes.data.status === "ok") return shieldRes.data.data.link;
    else throw shieldRes.data;
  }


  static async createInsuranceWithToku(person, beneficiaries, plan, stepId) {

    person.forecast = person.salud
    person.birthdate = person.dates

    //Generar Array ordenado de Beneficiarios
    var BenefData = [];
    if (beneficiaries) {
      beneficiaries.forEach((benef) => {
        BenefData.push({
          name: benef.name,
          surname: benef.surname,
          rut: benef.rut,
          parentesco: benef.tipo,
          fechaNacimiento: '' + benef.day + '-' + benef.month + '-' + benef.year,
        });
      });
      person.BenefData = BenefData;
    }

    var sponsorTokuRedirect = process.env.VUE_APP_SPONSOR_TOKU_REDIRECT

    //Se genera la petición a SIELD para generar la Insurance y conección a Instanda
    const jsonData = { person, planId: plan[0]._id, stepId: stepId, sponsorTokuRedirect: sponsorTokuRedirect };

    const shieldRes = await axios.post(
      `${VUE_APP_SHIELD_URL}/oncologico/createWithToku`,
      jsonData
    );
    if (shieldRes.data.status === "ok") return shieldRes.data.data;
    else throw shieldRes.data;
  }
}